<div id="home">

  <div id="circulos">
    <div class="work" (click)="redirect('work')">WORK</div>
  </div>

  <div id="icons">
    <a href="https://www.linkedin.com/in/fabguzman1991/" target="_blank">
      <figure>
        <img src="assets/img/linkedin.png" alt="linkedin" title="linkedin" />
      </figure>
    </a>
    <a href="https://www.instagram.com/fabxlous/" target="_blank">
      <figure>
        <img src="assets/img/instagram.png" alt="instagram" title="instagram" />
      </figure>
    </a>
    <a href="https://www.behance.net/fabxlous" target="_blank">
      <figure>
        <img src="assets/img/behance.png" alt="behance" title="behance" />
      </figure>
    </a>
    <a href="https://vimeo.com/fabxlous" target="_blank">
      <figure>
        <img src="assets/img/vimeo.png" alt="vimeo" title="vimeo" />
      </figure>
    </a>
  </div>
</div>