import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//Rutas
import { APP_ROUTING } from '../app.routes';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './components/inicio/welcome/welcome.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { FondoComponent } from './components/fondo/fondo.component';
import { HomeComponent } from './components/inicio/home/home.component';
import { WorkComponent } from './components/work/work.component';
import { AboutComponent } from './components/about/about.component';
import { RandomComponent } from './components/random/random.component';
import { FichaComponent } from './components/work/ficha/ficha.component';
import { CirculoscrollComponent } from './components/circuloscroll/circuloscroll.component';
import { FaviconComponent } from './components/favicon/favicon.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    InicioComponent,
    FondoComponent,
    HomeComponent,
    WorkComponent,
    AboutComponent,
    RandomComponent,
    FichaComponent,
    CirculoscrollComponent,
    FaviconComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTING
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
