import { Injectable } from '@angular/core';
import { ColorsService } from './colors.service';
import { Router } from '@angular/router';
import { CalculosService } from './calculos.service';
import * as $ from '../modules/jquery-3.5.1.min.js';

@Injectable({
  providedIn: 'root',
})
export class FxService {
  linkActive: boolean;
  // t: any;
  // scrollend: boolean;
  // scrollActual: number;

  constructor(
    private _colors: ColorsService,
    private _calculos: CalculosService,
    private router: Router
  ) {
    this.linkActive = false;
    // this.scrollend = false;
    // this.scrollActual = 0;
  }

  init(who: string, id?: string, num?: number) {
    if (who == 'inicio') {
      this.showWelcome();
    } else if (who == 'work') {
      this.showWork();
    } else if (who == 'fichas') {
      this.showFicha(id, num);
    }
  }

  scroll(who: string) {
    if (who == 'inicio') {
      this.scrollInicio();
    }
  }

  click(who: string, url: string) {
    if (who == 'home') {
      this.homeClick(url);
    } else if (who == 'work') {
      this.workClick(url);
    }
  }

  //--------------------------------------------------
  //--Inicio-----------------------------------------
  //--------------------------------------------------

  showWelcome() {
    $('#welcome div p:nth-child(1)').animate({ opacity: 1 }, 1500);
    setTimeout(() => {
      $('#welcome div p:nth-child(2)').animate({ opacity: 1 }, 1500);
    }, 200);
    setTimeout(() => {
      $('#welcome div p:nth-child(3)').animate({ opacity: 1 }, 1500);
    }, 400);
    setTimeout(() => {
      $('#welcome div p:nth-child(4)').animate({ opacity: 1 }, 1500);
    }, 600);
    $('#circulo-scroll').animate({ opacity: 1 }, 1000);
  }

  homeClick(url: string) {
    this.linkActive = true;
    let scroll = Math.round($(window).scrollTop());
    let wh = $(window).height();

    $('#welcome, #home, #about, #random, #circulo-scroll').fadeOut(500);

    let color1 = this._colors.blancoroto;
    let color2 = this._colors.rosapalo;

    $('#fondo').css({
      background: `linear-gradient(to bottom, ${this._colors.lima}, ${this._colors.blancoroto}, ${this._colors.blancoroto}, ${color1}, ${color2})`,
      backgroundPosition: `0% ${25 - this._calculos.invertirPorcentaje(this._calculos.calculaPorcentaje(scroll, wh))/3 }%`,
      backgroundSize: '100% 400%',
      animation: 'gradient 0.5s ease',
    });

    $('#fondo').on('animationend', () => {
      window.localStorage.setItem('work', 'true');
      this.linkActive = false;
      this.router.navigateByUrl(url);
    });
  }

  scrollInicio() {
    if (!this.linkActive) {
      let scroll = Math.round($(window).scrollTop());
      let maxScroll = $(document).height() - $(window).height();

      let p = this._calculos.calculaPorcentaje(scroll, maxScroll);

      $('#fondo').css({
        background: `linear-gradient(to bottom, ${this._colors.verde} 0%, ${this._colors.lima} 20%, ${this._colors.blancoroto} 30%, ${this._colors.blancoroto}, ${this._colors.lima} 50%, ${this._colors.verde} 70%, ${this._colors.naranja} 80%, ${this._colors.rosapalo} 95%, ${this._colors.blancoroto} 100%)`,
        backgroundSize: '100% 600%',
        backgroundPosition: `0% ${p}%`,
      });
    }
  }

  scrollToWork(){
    $('html').animate({scrollTop: $("#home").offset().top}, 700);
  }

  

  //--------------------------------------------------
  //--Work------------------------------------------
  //--------------------------------------------------
  bgPositionActual: number;

  showWork() {
    $('#fondo').css({
      background: `linear-gradient(to bottom, ${this._colors.blancoroto}, ${this._colors.rosapalo}, ${this._colors.rosapalo}, ${this._colors.rosa}, ${this._colors.rosa}, ${this._colors.rosapalo}, ${this._colors.rosapalo}, ${this._colors.naranja}, ${this._colors.naranja}, ${this._colors.azul})`,
      backgroundSize: '100% 1000%',
      backgroundPosition: '0% 0%',
    });

    $('#work h1:nth-child(1)').animate({ opacity: 1 }, 1000);
    setTimeout(() => {
      $('#work h1:nth-child(2)').animate({ opacity: 1 }, 1000);
    }, 100);
    setTimeout(() => {
      $('#work h1:nth-child(3)').animate({ opacity: 1 }, 1000);
    }, 200);
    setTimeout(() => {
      $('#work h1:nth-child(4)').animate({ opacity: 1 }, 1000);
    }, 300);

    this.bgPositionActual = 0;
  }

  fondoWork(id: string, estado: string) {
    let bgPosition: number;

    if (!this.linkActive) {
      if (estado == 'enter') {
        if (id == 'graphicdesign') {
          bgPosition = 25;
        } else if (id == 'artdirection') {
          bgPosition = 50;
        } else if (id == 'setdesign') {
          bgPosition = 75;
        } else if (id == 'musicvideo') {
          bgPosition = 100;
        }
      } else if (estado == 'leave') {
        bgPosition = 0;
      }

      $('#fondo')
        .stop()
        .animate(
          {
            'background-position-x': '0%',
            'background-position-y': `${bgPosition}%`,
          },
          2000,
          () => {
            this.bgPositionActual = bgPosition;
            $(this).css({ backgroundPosition: `0% ${this.bgPositionActual}%` });
          }
        );
    }
  }

  workClick(url: string) {
    this.linkActive = true;
    let color1, color2;
    
    $('#work h1:nth-child(4)').stop().fadeTo(500, 0);
    setTimeout(() => {
      $('#work h1:nth-child(3)').stop().fadeTo(500, 0);
    }, 100);
    setTimeout(() => {
      $('#work h1:nth-child(2)').stop().fadeTo(500, 0);
    }, 200);
    setTimeout(() => {
      $('#work h1:nth-child(1)').stop().fadeTo(500, 0);
    }, 300);

    if (url == 'graphicdesign') {
      color1 = this._colors.rosapalo;
      color2 = this._colors.rosa;
    } else if (url == 'artdirection') {
      color1 = this._colors.rosa;
      color2 = this._colors.rosapalo;
    } else if (url == 'setdesign') {
      color1 = this._colors.rosapalo;
      color2 = this._colors.naranja;
    } else if (url == 'musicvideo') {
      color1 = this._colors.naranja;
      color2 = this._colors.azul;
    }

    $('#fondo')
      .stop()
      .css({
        background: `linear-gradient(to bottom, ${color1}, ${color2}, ${color2})`,
        backgroundPosition: '0% 0%',
        backgroundSize: '100% 200%',
      });

    $('#fondo')
      .stop()
      .animate(
        {
          'background-position-x': '0%',
          'background-position-y': '100%',
        },
        800,
        () => {
          this.router.navigateByUrl('work/' + url);
          this.linkActive = false;
        }
      );

    $('#fondo').on('animationend', () => {});
  }


  //--------------------------------------------------
  //--Ficha------------------------------------------
  //--------------------------------------------------
  fichaActual: number;
  fichaScrollActive: boolean;
  scrollActual: number;

  showFicha(id: string, num: number) {
    this.fichaScrollActive = false;
    this.fichaActual = -1;
    this.scrollActual = 0;
    let color: string;

    setTimeout(() => {
      let aux = $('#fichas .menu h2').length;
      let n = 0;
      for (let i = 1; i <= aux; i++) {
        setTimeout(() => {
          $('#fichas .menu h2:nth-child(' + i + ')').animate(
            { opacity: 1 },
            1000
          );
        }, n);
        n += 100;
        if (i == aux) {
          setTimeout(() => {
            $('#fichas .menu h1').animate({ opacity: 1 }, 1000);
          }, n);
        }
      }
      $('#circulo-scroll').animate({ opacity: 1 }, 1000);
    }, 10);

    $('#fichas .menu').css({ height: $(window).height() });

    if (id == 'graphicdesign') {
      color = this._colors.rosa;
    } else if (id == 'artdirection') {
      color = this._colors.rosapalo;
    } else if (id == 'setdesign') {
      color = this._colors.naranja;
    } else if (id == 'musicvideo') {
      color = this._colors.azul;
    }

    $('#fondo-ficha').css({ background: color });
  }

  //Scroll
  fondoFicha(id: string, n: number){
    let scroll = Math.round($(window).scrollTop());
    let maxScroll = Math.round($(document).height()-$(window).height());
    
    // let scrollTo: number;
    
    
    // if(!this.fichaScrollActive){
    //   this.fichaScrollActive = true;

    //   if(scroll > this.scrollActual){
    //     if(this.fichaActual < n){
    //       this.fichaActual ++;
    //       scrollTo = Math.round($("#fichas .ficha").eq((this.fichaActual)).offset().top);
    //     }
    //     this.scrollActual = scrollTo;
    //   }
    //   else if(scroll < this.scrollActual){
    //     if(this.fichaActual > 0){
    //       this.fichaActual --;
    //       console.log(`Ficha Actual: ${this.fichaActual}`);
    //       scrollTo = Math.round($("#fichas .ficha").eq(this.fichaActual).offset().top);
    //     }
    //     else if(this.fichaActual == 0){
    //       this.fichaActual = -1;
    //       scrollTo = 0;
    //     }
    //     this.scrollActual = scrollTo;
    //   }
    
    //   $('html').animate({ scrollTop: scrollTo }, 700, () => {
    //     this.fichaScrollActive = false;
    //   });

    // }

    let p = this._calculos.calculaPorcentaje(scroll, (maxScroll/n));

    let fondo = $("#fondo-ficha");
    let degradado: string;
    
    if(id == "graphicdesign"){
      degradado =  `radial-gradient(${this._colors.azul} ${p-400}%, ${this._colors.rosapalo} ${p-360}%, ${this._colors.azul} ${p-330}%, ${this._colors.blancoroto} ${p-300}%, ${this._colors.naranja} ${p-235}%, ${this._colors.rosa} ${p-200}%, ${this._colors.rosapalo} ${p-100}%, ${this._colors.rosa} ${p}%, ${this._colors.rosa} ${p+100}%)`
    }
    else if(id == "artdirection"){
      degradado =  `radial-gradient(${this._colors.lima} ${p-400}%, ${this._colors.rosa} ${p-330}%, ${this._colors.naranja} ${p-300}%, ${this._colors.lima} ${p-230}%, ${this._colors.rosapalo} ${p-200}%, ${this._colors.naranja} ${p-130}%, ${this._colors.blancoroto} ${p-100}%, ${this._colors.rosapalo} ${p}%, ${this._colors.rosapalo} ${p+100}%)`
    }
    else if(id == "setdesign"){
      degradado =  `radial-gradient(${this._colors.blancoroto} ${p-420}%, ${this._colors.naranja} ${p-350}%, ${this._colors.rosapalo} ${p-330}%,${this._colors.blancoroto} ${p-300}%, ${this._colors.azul} ${p-230}%, ${this._colors.verde} ${p-200}%, ${this._colors.lima} ${p-160}%, ${this._colors.naranja} ${p-130}%, ${this._colors.blancoroto} ${p-100}%, ${this._colors.verde} ${p-20}%, ${this._colors.naranja} ${p}%, ${this._colors.naranja} ${p+100}%)`
    }
    else if(id == "musicvideo"){
      degradado =  `radial-gradient(${this._colors.naranja} ${p-500}%, ${this._colors.rosa} ${p-445}%, ${this._colors.azul} ${p-430}%, ${this._colors.lima} ${p-400}%, ${this._colors.azul} ${p-345}%, ${this._colors.rosa} ${p-330}%, ${this._colors.lima} ${p-300}%, ${this._colors.rosapalo} ${p-245}%, ${this._colors.naranja} ${p-230}%, ${this._colors.rosapalo} ${p-200}%, ${this._colors.azul} ${p-145}%, ${this._colors.rosa} ${p-130}%, ${this._colors.rosapalo} ${p-100}%, ${this._colors.rosa} ${p-30}%, ${this._colors.azul} ${p}%, ${this._colors.azul} ${p+100}%)`
    }
     
    fondo.css({ background: degradado});
  }


  fichaClick(x: number) {
    this.fichaScrollActive = true;
    this.fichaActual = x-1;
    
    let num = $('#fichas .ficha').eq(x).offset().top;
    
    let t: number;
    if (x == 0) {
      t = 600;
    } else if (x == 1) {
      t = 1000;
    } else if (x == 2) {
      t = 1400;
    } else if (x == 3) {
      t = 1800;
    } else {
      t = 2200;
    }
    $('html').animate({ scrollTop: num }, t, 'swing', () => {
      this.fichaScrollActive = false;
    });
  }

  
}

