import { Injectable } from '@angular/core';
import { ColorsService } from './colors.service';
import { CalculosService } from './calculos.service';
import { FxService } from './fx.service';

@Injectable({
  providedIn: 'root',
})
export class FichaService {
  constructor(private _colors: ColorsService, private _calculos: CalculosService, private _fx: FxService) {}

  proyectos = [
    //--GRAPHIC DESIGN-------------------------------------
    {
      id: 'graphicdesign',
      title: 'graphic design',
      content: [
        {
          id: 'iris',
          titulo: 'Iris',
          tituloCompleto: 'Iris',
          fecha: 'Barcelona, 2016',
          descripcion: 'Art direction project about the Hero’s Journey',
          equipo: 'Concept & design with Riccardo Marras',
          imgs: [
            './assets/img/graphicdesign/iris/img1.jpg',
            './assets/img/graphicdesign/iris/img2.jpg',
            './assets/img/graphicdesign/iris/img3.jpg',
            './assets/img/graphicdesign/iris/img4.jpg',
          ],
        },
        {
          id: 'bly',
          titulo: 'Bad Lil Young',
          tituloCompleto: 'Bad Lil Young',
          fecha: 'Barcelona, 2022',
          descripcion: 'BLY is a musical project, we curate playlists based on moods & inspired by art, fashion and colors.',
          equipo: 'Concept & Art direction.',
          imgs: [
            './assets/img/graphicdesign/bad_lil_young/img1.png',
            './assets/img/graphicdesign/bad_lil_young/img2.png',
            './assets/img/graphicdesign/bad_lil_young/img3.png',
            './assets/img/graphicdesign/bad_lil_young/img4.png',
          ],
        },
        {
          id: 'modoki',
          titulo: 'Modoki',
          tituloCompleto: 'Modoki',
          fecha: 'Barcelona, 2022',
          descripcion:
            'An amazing plant-based project, inspired on the streets of Tokyo & manga culture',
          equipo: 'Art direction & graphic design',
          imgs: [
            './assets/img/graphicdesign/modoki/img1.png',
            './assets/img/graphicdesign/modoki/img2.png',
            './assets/img/graphicdesign/modoki/img3.png',
            './assets/img/graphicdesign/modoki/img4.png',
          ],
        },
        {
          id: 'tedx',
          titulo: 'Tedx',
          tituloCompleto: 'Tedx Barcelona Women',
          fecha: 'Barcelona, 2018',
          descripcion: 'Art direction and graphics for Tedx Barcelona',
          equipo: 'Concept & design.',
          imgs: [
            './assets/img/graphicdesign/tedx/img1.jpeg',
            './assets/img/graphicdesign/tedx/img2.jpeg',
            './assets/img/graphicdesign/tedx/img3.jpeg',
            './assets/img/graphicdesign/tedx/img4.gif',
          ],
        },
      ],
    },

    //--ART DIRECTION-------------------------------------
    {
      id: 'artdirection',
      title: 'art direction',
      content: [
        {
          id: 'fujifilm',
          titulo: 'Fujifilm',
          tituloCompleto: 'Fujifilm X Playground',
          fecha: 'Barcelona, 2018',
          descripcion: 'Playground’s social experiment introducing Instax Square SQ6',
          equipo: 'Art direction & direction by me / Motion graphics: David Serrano / Production: Maria Celeste Aued / Concept: Anna Bartolome / Edition: Igone Arreitunandia',
          imgs: [
            './assets/img/artdirection/fujifilm/img1.png',
            './assets/img/artdirection/fujifilm/img2.gif',
            './assets/img/artdirection/fujifilm/img3.png',
            './assets/img/artdirection/fujifilm/img4.png',
          ],
        },
        {
          id: 'natura',
          titulo: 'Natura',
          tituloCompleto: 'Video Content X Natura',
          fecha: 'Barcelona, 2020',
          descripcion: 'Art, edition & visual direction for some video projects x Natura',
          equipo: 'Models: Li-Ann & Andrea / Products by Natura',
          imgs: [
            './assets/img/artdirection/natura/img1.gif',
            './assets/img/artdirection/natura/img2.png',
            './assets/img/artdirection/natura/img3.png',
            './assets/img/artdirection/natura/img4.png',
          ],
        },
        {
          id: 'soundslikeramen',
          titulo: 'Sounds Like Ramen',
          tituloCompleto: 'Sounds Like Ramen',
          fecha: 'Barcelona, 2020',
          descripcion:
            'Brand identity, video edition and content creator x an instagram project about Ramen (Japanese noodle soup)',
          equipo: 'Concept & design with Laura Torres & Niko Vestoshkin',
          imgs: [
            './assets/img/artdirection/soundslikeramen/img1.jpg',
            './assets/img/artdirection/soundslikeramen/img2.jpg',
            './assets/img/artdirection/soundslikeramen/img3.gif',
            './assets/img/artdirection/soundslikeramen/img4.gif',
          ],
        },
        {
          id: 'samsung',
          titulo: 'Samsung Galaxy',
          tituloCompleto: 'Samsung Galaxy 2019 x Playground',
          fecha: 'Bogotá, 2018',
          descripcion:
            'Playground’s social experiment introducing Samsung Galaxy S9',
          equipo: 'Art direction & direction / Production: Reinaldo Lancaster / Edition & Graphics: David Serrano / Script: Cristina Tosca',
          imgs: [
            './assets/img/artdirection/samsung/img1.png',
            './assets/img/artdirection/samsung/img2.gif',
            './assets/img/artdirection/samsung/img3.gif',
            './assets/img/artdirection/samsung/img4.gif',
          ],
        },
      ],
    },

    //--SET DESIGN-------------------------------------
    {
      id: 'setdesign',
      title: 'set design',
      content: [
        {
          id: 'doiy',
          titulo: 'Doiy Design',
          tituloCompleto: 'Lifestyle x DOIY design',
          fecha: 'Barcelona, 2014',
          descripcion: 'Concept & Photography & Edit',
          equipo: 'All products x DOIY Design',
          imgs: [
            './assets/img/setdesign/doiy/img1.jpg',
            './assets/img/setdesign/doiy/img2.jpg',
            './assets/img/setdesign/doiy/img3.jpg',
            './assets/img/setdesign/doiy/img4.jpg',
          ],
        },
        {
          id: 'naturalifestyle',
          titulo: 'Natura',
          tituloCompleto: 'Lifestyle x Natura',
          fecha: 'Barcelona, 2020',
          descripcion: 'Concept & Photography & Edit',
          equipo: 'All products x Natura',
          imgs: [
            './assets/img/setdesign/naturalifestyle/img1.jpg',
            './assets/img/setdesign/naturalifestyle/img2.jpg',
            './assets/img/setdesign/naturalifestyle/img3.jpg',
            './assets/img/setdesign/naturalifestyle/img4.jpg',
          ],
        },
        {
          id: 'duomo',
          titulo: 'Duomo Ediciones',
          tituloCompleto: 'Books x Duomo Editorial',
          fecha: 'Barcelona, 2020',
          descripcion: 'Concept & Photography & Edit',
          equipo: 'All products x Duomo Ediciones Barcelona',
          imgs: [
            './assets/img/setdesign/duomo/img1.jpg',
            './assets/img/setdesign/duomo/img2.gif',
            './assets/img/setdesign/duomo/img3.jpg',
            './assets/img/setdesign/duomo/img4.jpg',
          ],
        },
        {
          id: 'naturabooks',
          titulo: 'books x natura',
          tituloCompleto: 'Books x natura',
          fecha: 'Barcelona, 2020',
          descripcion: 'Concept & Photography & Edit',
          equipo: 'All products x Natura',
          imgs: [
            './assets/img/setdesign/naturabooks/img1.jpg',
            './assets/img/setdesign/naturabooks/img2.jpg',
            './assets/img/setdesign/naturabooks/img3.jpg',
            './assets/img/setdesign/naturabooks/img4.jpg',
          ],
        },
      ],
    },

    //--MUSIC-VIDEO-------------------------------------
    {
      id: 'musicvideo',
      title: 'music videos',
      content: [
        {
          id: 'ghosttrip',
          titulo: 'Ghost Trip',
          tituloCompleto: 'Ghost Trip "Éxito"',
          fecha: 'Barcelona, 2019',
          descripcion: 'Stylism & Art direction with Raul Maldonado',
          equipo: 'Edition + Making of: Natalia Chackmakchyan / Directors: Blanca Pia & Vicent Torres / Producers: Maria Celeste Aued & Ana Portella / DOP: Marcel Pascual / Make up: Esteve Sinte',
          imgs: [
            './assets/img/musicvideo/ghosttrip/img1.jpg',
            './assets/img/musicvideo/ghosttrip/img2.jpg',
            './assets/img/musicvideo/ghosttrip/img3.jpg',
            './assets/img/musicvideo/ghosttrip/img4.jpg',
          ],
          video: 'https://www.youtube.com/embed/c6kIv1uHzAw',
        },
        {
          id: 'pinkertones',
          titulo: 'Pinker Tones',
          tituloCompleto: 'Pinker Tones "LAVINIA"',
          fecha: 'Barcelona, 2019',
          descripcion: 'Stylism with Raul Maldonado',
          equipo: 'Edition: Natalia Chackmakchyan / Directors: Blanca Pia & Vicent Torres / Producers: Ana Portella & Maria Celeste Aued / DOP: Marcel Pascual / Make up: Esteve Sinte',
          imgs: [
            './assets/img/musicvideo/pinkertones/img1.jpg',
            './assets/img/musicvideo/pinkertones/img2.jpg',
            './assets/img/musicvideo/pinkertones/img3.jpg',
            './assets/img/musicvideo/pinkertones/img4.jpg',
          ],
        },

        {
          id: 'maruja',
          titulo: 'Maruja Limon',
          tituloCompleto: 'Maruja Limon "Tu cuerpo se mueve"',
          fecha: 'Barcelona, 2019',
          descripcion:
            'Art direction & Stylism with Raul Maldonado and Mariola Viejo',
          equipo: 'Edition: Natalia Chackmakchyan / Directors: Blanca Pia & Vicent Torres / Producers: Maria Celeste Aued & Ana Portella / DOP: Marcel Pascual / Make up: Esteve Sinte',
          imgs: [
            './assets/img/musicvideo/maruja/img1.jpg',
            './assets/img/musicvideo/maruja/img2.jpg',
            './assets/img/musicvideo/maruja/img3.jpg',
            './assets/img/musicvideo/maruja/img4.jpg',
          ],
          video: 'https://www.youtube.com/embed/t9M7yYkFgg4',
        },
        {
          id: 'falciots',
          titulo: 'Falciots Ninja',
          tituloCompleto: 'Falciots Ninja "festa"',
          fecha: 'Barcelona, 2019',
          descripcion: 'Stylism & Art Direction Assistant',
          equipo: 'Director: Martí Colomer / Main Producer: Nil Pagès / AD: Begoña Baeza / DOP: Ángel Manrique / Executive producer: Ana Gui / Production assistant: Carolina Rodriguez',
          imgs: [
            './assets/img/musicvideo/falciots/img1.jpg',
            './assets/img/musicvideo/falciots/img2.jpg',
            './assets/img/musicvideo/falciots/img3.jpg',
            './assets/img/musicvideo/falciots/img4.jpg',
          ],
          video: 'https://www.youtube.com/embed/1wrSpq-hahg',
        },
        {
          id: 'davylyons',
          titulo: 'Davy Lyons',
          tituloCompleto: 'Davy Lyons "golden hair"',
          fecha: 'Barcelona, 2019',
          descripcion: 'Stylism & Art Direction Assistant',
          equipo: 'Director: Martí Colomer / Main Producer: Nil Pagès / AD: Begoña Baeza / DOP: Ángel Manrique / Executive producer: Ana Gui / Production assistant: Carolina Rodriguez',
          imgs: [
            './assets/img/musicvideo/davylyons/img1.jpg',
            './assets/img/musicvideo/davylyons/img2.jpg',
            './assets/img/musicvideo/davylyons/img3.jpg',
            './assets/img/musicvideo/davylyons/img4.jpg',
          ],
          video: 'https://www.youtube.com/embed/41fNa3_WWzA',
        },
      ],
    },
  ];

  about = [
    {
      id: '2020',
      content: [
        {
          text: 'FREELANCE ART DIRECTOR & GRAPHIC DESIGNER',
          fecha: 'REMOTE / 2018 - 2020',
        },
        {
          text: '',
          fecha: 'BARCELONA / 2020',
        },
      ],
    },
    {
      id: '2019',
      content: [
        {
          text: 'FREELANCE ART DIRECTOR & GRAPHIC DESIGNER',
          fecha: 'REMOTE / 2018 - 2020',
        },
        {
          text: '',
          fecha: 'BARCELONA / 2017 - 2019',
        },
        {
          text:
            '',
          fecha: 'BERLIN / 2019',
        },
        {
          text: '',
          fecha: 'BERLIN / 2019',
        },
      ],
    },
    {
      id: '2018',
      content: [
        {
          text: 'FREELANCE ART DIRECTOR & GRAPHIC DESIGNER',
          fecha: 'REMOTE / 2018 - 2020',
        },
        {
          text: '',
          fecha: 'BARCELONA / 2017 - 2019',
        },
        {
          text:
            'LECTURER: MUSICAL CINEMA & ART DIRECTION x UNIVERSITAT DE BARCELONA',
          fecha: 'BARCELONA / 2018',
        },
      ],
    },
    {
      id: '2017',
      content: [
        {
          text: '',
          fecha: 'BARCELONA / 2017 - 2019',
        },
        {
          text: '',
          fecha: 'BARCELONA / 2017',
        },
      ],
    },
    {
      id: '2016',
      content: [
        {
          text: '',
          fecha: 'BARCELONA / 2016',
        },
        {
          text: '',
          fecha: 'BARCELONA / 2017',
        },
      ],
    },
    {
      id: '2015',
      content: [
        {
          text: '',
          fecha: 'BARCELONA / 2013 - 2015',
        },
        {
          text: 'ONE YEAR COURSE OF FILM DIRECTION X PLATÓ DE CINEMA',
          fecha: 'BARCELONA / 2014 - 2015',
        },
        {
          text: 'GRADUATE IN GRAPHIC DESIGN X EASD PAU GARGALLO',
          fecha: 'BARCELONA / 2009-2013',
        },
      ],
    },
  ];
  
  getCategoria(id: string) {
    let categoria;
    this.proyectos.map((x) => {
      if (x.id == id) {
        categoria = x;
      }
    });
    return categoria;
  }

  getAbout() {
    return this.about;
  }

}
