import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './app/components/inicio/inicio.component';
import { WorkComponent } from './app/components/work/work.component';
import { AboutComponent } from './app/components/about/about.component';
import { RandomComponent } from './app/components/random/random.component';
import { FichaComponent } from './app/components/work/ficha/ficha.component';

const RUTAS: Routes = [
  { path: '', component: InicioComponent},
  { path: 'work', component: WorkComponent},
  { path: 'about', component: AboutComponent},
  { path: 'random', component: RandomComponent},
  { path: 'work/:id', component: FichaComponent},
  { path: '**', redirectTo: '', pathMatch: 'full' },
  { path: '**/**', redirectTo: '', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(RUTAS);