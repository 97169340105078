import { Injectable } from '@angular/core';
import { FichaService } from './ficha.service';
import * as $ from '../modules/jquery-3.5.1.min.js';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  about: { id: string; content: { text: string; fecha: string }[] }[];
  opened: string;
  constructor(private _ficha: FichaService) {
    this.about = this._ficha.getAbout();
  }

  init() {
    setTimeout(() => {
      $('#about .year').animate({ opacity: 1 }, 1000);
      $('#about #2020 .circulo').css({
        background: 'radial-gradient(var(--rosa) 0%, var(--rosapalo) 100%',
      });
      $('#about #2019 .circulo').css({
        background: 'radial-gradient(var(--rosapalo) 0%, var(--naranja) 100%',
      });
      $('#about #2018 .circulo').css({
        background: 'radial-gradient(var(--naranja) 0%, var(--azul) 100%',
      });
      $('#about #2017 .circulo').css({
        background: 'radial-gradient(var(--verde) 0%, var(--lima) 100%',
      });
      $('#about #2016 .circulo').css({
        background: 'radial-gradient(var(--rosa) 0%, var(--rosapalo) 100%',
      });
      $('#about #2015 .circulo').css({
        background: 'radial-gradient(var(--naranja) 0%, var(--azul) 100%',
      });
    }, 10);

    $('#about .last, #about .next').hide();
  }

  getAbout() {
    return this.about;
  }

  circuloOpen(id: string) {
    
    let yearWidth = $('#about #' + id).width();
    let yearHeight = $('#about #' + id).height();
    
    if (this.opened != null && this.opened != id) {
      this.circuloClose(this.opened);
    }
    
    let w = 400;
    
    
    if($(window).width() < 400){
      w = $(window).width();
    }

    if (this.opened != id) {
      $('#about #' + id + ' .circulo').css({
        top: yearHeight / 2,
        left: yearWidth / 2,
      });
    }

    if($(`#about #${id} .circulo .last`).is(":hidden")){
      $(`#about #${id} .circulo .next`).fadeIn(600);
    }    

    $('#about #' + id + ' .circulo').animate(
      {
        width: w,
        height: w,
        top: -w / 2 + yearHeight / 2,
        left: -w / 2 + yearWidth / 2,
        opacity: 1,
      },
      600,
      'swing'
    );

    if($(window).width() < 400){
      w = $(window).width();
      $(
        '#about #' +
          id +
          ' .circulo .content .experience, #about #' +
          id +
          ' .circulo .content-large .experience'
      ).animate(
        {
          fontSize: '20px',
          opacity: 1,
        },
        600
      );
      $(
        '#about #' +
          id +
          ' .circulo .content .date, #about #' +
          id +
          ' .circulo .content-large .date'
      )
        .stop()
        .animate(
          {
            fontSize: '10px',
            opacity: 1,
          },
          600
        );
    }
    else{

      $(
        '#about #' +
          id +
          ' .circulo .content .experience, #about #' +
          id +
          ' .circulo .content-large .experience'
      ).animate(
        {
          fontSize: '25px',
          opacity: 1,
        },
        600
      );
      $(
        '#about #' +
          id +
          ' .circulo .content .date, #about #' +
          id +
          ' .circulo .content-large .date'
      )
        .stop()
        .animate(
          {
            fontSize: '15px',
            opacity: 1,
          },
          600
        );
    }

    for (let i = 1; i <= $('#about').children().length; i++) {
      if ($(`#about .year:nth-child(${i})`).attr('id') != id) {
        $(`#about .year:nth-child(${i})`).css({ zIndex: 1 });
      }
    }

    this.opened = id;
  }

  circuloClose(id: string) {
    let yearWidth = $('#about #' + id).width();
    let yearHeight = $('#about #' + id).height();
    let w = 0;

    $(`#about #${id} .circulo .content-large`).animate(
      { marginLeft: '100%' },
      500
    );
    $(`#about #${id} .circulo .last`).fadeOut(500);
    $(`#about #${id} .circulo .next`).fadeOut(500);

    $('#about #' + id + ' .circulo')
      .stop()
      .animate(
        {
          width: w,
          height: w,
          top: yearHeight / 2,
          left: yearWidth / 2,
          opacity: 0,
        },
        600,
        'swing'
      );
    $(
      '#about #' +
        id +
        ' .circulo .content p, #about #' +
        id +
        ' .circulo .content-large p'
    )
      .stop()
      .animate(
        {
          fontSize: 0,
          opacity: 0,
        },
        600
      );
    $(`#about .year`).css({ zIndex: 3 });
  }

  circuloExit() {
    if (this.opened != null) {
      this.circuloClose(this.opened);
    }
  }

  next(id: string) {
    $(`#about #${id} .circulo .content-large`).animate(
      { marginLeft: '-100%' },
      500
    );
    $(`#about #${id} .circulo .next`).fadeOut(500);
    $(`#about #${id} .circulo .last`).fadeIn(500);
  }

  last(id: string) {
    $(`#about #${id} .circulo .content-large`).animate(
      { marginLeft: '100%' },
      500
    );
    $(`#about #${id} .circulo .last`).fadeOut(500);
    $(`#about #${id} .circulo .next`).fadeIn(500);
  }
}
