import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FichaService } from '../../../services/ficha.service';
import { ResizeService } from '../../../services/resize.service';
import { FxService } from '../../../services/fx.service';

@Component({
  selector: 'app-ficha',
  templateUrl: './ficha.component.html',
  styleUrls: ['./ficha.component.css']
})
export class FichaComponent implements OnInit {

  id: string;
  titulo: string;
  content: [];

  constructor(private _ruta: ActivatedRoute, private _fx: FxService, private _fichaService: FichaService, private _resizeService: ResizeService) {
    this._ruta.params.subscribe( param => {
      let categoria = _fichaService.getCategoria(param['id']);
      
      this.id = categoria.id;
      this.titulo = categoria.title;
      this.content = categoria.content;
    });
  }
  
  ngOnInit(): void {
    this._fx.init('fichas', this.id, this.content.length);
    this._resizeService.resize();
  }

  fondo(){
    this._fx.fondoFicha(this.id, this.content.length);
  }

  click(x: number){
    this._fx.fichaClick(x);
  }

  resize(){
    this._resizeService.resize();
    let fondo = document.getElementById('fondo-ficha');
    fondo.style.height = `${window.innerHeight}px`;
  }

}
