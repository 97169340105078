import { Component, OnInit } from '@angular/core';
import { FxService } from '../../../services/fx.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private _fx: FxService) { }

  ngOnInit(): void { }

  redirect(url: string){
    this._fx.click('home' ,url);
  }

}
