import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-random',
  templateUrl: './random.component.html',
  styleUrls: ['./random.component.css']
})
export class RandomComponent implements OnInit {

  random: {id: string, title: string, content: string}[];

  constructor(private _resize: ResizeService) {
  }

  ngOnInit(): void { 
    this._resize.random();
  }

}
