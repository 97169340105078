import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about.service';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  mobile: boolean;

  y20: {id: string, content: {text: string, fecha: string}[]};
  y19: {id: string, content: {text: string, fecha: string}[]};
  y18: {id: string, content: {text: string, fecha: string}[]};
  y17: {id: string, content: {text: string, fecha: string}[]};
  y16: {id: string, content: {text: string, fecha: string}[]};
  y15: {id: string, content: {text: string, fecha: string}[]};

  constructor(private _about: AboutService, private _device: DeviceService) {
    
    let abouts = this._about.getAbout();
    
    this.y20 =  abouts[0];
    this.y19 =  abouts[1];
    this.y18 =  abouts[2];
    this.y17 =  abouts[3];
    this.y16 =  abouts[4];
    this.y15 =  abouts[5];

    
  }
  
  ngOnInit(): void { 
    this._about.init();
    this.mobile = this._device.isMobile();
  }

  circuloOpen(id: string, e: Event){ 
    if(e.type == 'mouseenter' && !this.mobile){
      this._about.circuloOpen(id);
    }
    if(e.type == 'touchstart' && this.mobile){
      this._about.circuloOpen(id);
    }
    
  }

  circuloClose(id: string, e: Event){
    if(e.type == 'mouseleave'){
      this._about.circuloClose(id);
    }
  }

  exit(){
    this._about.circuloExit();
  }

  next(id: string){
    this._about.next(id);
  }
  
  last(id: string){
    this._about.last(id);
  }

}
