<div id="random">
  <div class="container">
    <div class="content wimm">
      <h1>WHERE IS<br />MY MIND?</h1>
      <p>
        WIMM IS A DUPLA-PROJECT WITH MY PARTNER, ERIC. OUR PURPOSE IS TO CREATE,
        DESIGN AND CODING WEB SPACES THINKING "OUT OF THE BOX".
      </p>
    </div>

    <div class="content berlin">
      <h1>THE ECHO OF<br />BERLIN'S<wbr/> LIGHT</h1>
      <p>
        I SPENT THE SUMMER OF 2019 LIVING IN BERLIN ON MY OWN. THIS PROJECT IS A
        VISUAL ODE ABOUT LONELINESS AND FREEDOM.<br /><a
          href="https://vimeo.com/469439942"
          target="_blank"
          >VIMEO</a
        >
      </p>
    </div>

    <div class="content devvie">
      <h1>DEVVIE</h1>
      <p>
        SINCE 2018, I'M PLAYING SYNTHESIZERS AND SINGING. NOW I'M IN A BAND
        CALLED DEVVIE. WE MIXED POST PUNK WITH EVERYTHING WE LOVE. SOON LP.<br/><a
        href="https://www.instagram.com/devvie__/"
        target="_blank"
        >INSTAGRAM</a>
      </p>
    </div>

    
    <div class="content dj">
      <h1>DJ FABXLOUS</h1>
      <p>
        I CONSIDERED MYSELF A MUSIC-HOLIC. NOW I'M THE ONE WHO DJS AT FRIENDS
        EVENTS AND DO STREAMINGS.
      </p>
    </div>
  </div>
</div>
