import { Component, OnInit } from '@angular/core';
import { FaviconService } from '../../services/favicon.service';

@Component({
  selector: 'app-favicon',
  templateUrl: './favicon.component.html',
  styleUrls: ['./favicon.component.css']
})
export class FaviconComponent implements OnInit {

  constructor(private _fav: FaviconService) {
    this._fav.init();
  }

  ngOnInit(): void {}

}
