import { Component, OnInit } from '@angular/core';
import { FxService } from '../../services/fx.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  constructor(private _fx: FxService) { }

  ngOnInit(): void {
    this._fx.showWork();
    this._fx.init('work');
  }

  fondo(id: string, estado: string){
    this._fx.fondoWork(id, estado);
  }

  redirect(url: string){
    this._fx.click('work' ,url);
  }

}
