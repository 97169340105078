import { Injectable } from '@angular/core';
import * as $ from '../modules/jquery-3.5.1.min.js';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  aux = 1;
  fav: string[] = []

  constructor() {
    for(let i = 1; i <=224; i++){
      this.fav.push(`../assets/img/favicons/(${i}).png`);
    }
  }

  init(){
    let favicon = $("#fav");
    favicon.attr("href", this.fav[80]);

    setInterval( () => {
      favicon.attr("href", this.fav[this.aux]);
      if(this.aux < 240 ){
        this.aux++;
      }
      else{
        this.aux = 1;
      }
    }, 30);
    

//     document.head = document.head || document.getElementsByTagName('head')[0];

// function changeFavicon(src) {
//  var link = document.createElement('link'),
//      oldLink = document.getElementById('dynamic-favicon');
//  link.id = 'dynamic-favicon';
//  link.rel = 'shortcut icon';
//  link.href = src;
//  if (oldLink) {
//   document.head.removeChild(oldLink);
//  }
//  document.head.appendChild(link);
// }
    
    // console.log($("head").children().attr('data-link'));

    // $("head link[data-link='fav']").remove().append(`<link rel="icon" type="image/x-icon" href="${this.fav[20]}" data-link="fav"/>`);
    // setInterval( () => {
      
    // }, 20);
  }
}
