import { Injectable } from '@angular/core';
import * as $ from '../modules/jquery-3.5.1.min.js';

@Injectable({
  providedIn: 'root'
})
export class CirculoscrollService {

  ctx: CanvasRenderingContext2D;

  constructor() { }

  init(){
    let canvas = <HTMLCanvasElement>document.getElementById('canvas-scroll');
    this.ctx = canvas.getContext('2d');
    this.ctx.beginPath();
    this.ctx.arc(13,13,13, -Math.PI/2, (-Math.PI/2)-(Math.PI*2), true);
    this.ctx.lineTo(13,13);
    this.ctx.fillStyle = '#fff';
    this.ctx.fill();
    this.ctx.strokeStyle = "#fff";
    this.ctx.stroke();
  }

  draw(){
    let p = this.calc();
    this.ctx.clearRect(0, 0, 40, 40);
    this.ctx.beginPath();
    this.ctx.arc(13,13,13, -Math.PI/2, (-Math.PI/2)-(Math.PI*p*2), true);
    this.ctx.lineTo(13,13);
    this.ctx.fillStyle = '#fff';
    this.ctx.fill();
        
    if(Math.round($(window).scrollTop()) < ($(document).height() - $(window).height()) - 1){
      this.ctx.strokeStyle = "#fff";
      this.ctx.stroke();
    }
  }

  calc(){
    return this.calculaPorcentaje( $(window).scrollTop(), $(document).height() - $(window).height() );
  }

  private calculaPorcentaje(numeroVariable: number, numeroMaximo: number) {
    return Math.abs((numeroVariable / numeroMaximo)-1);
  }

}
