import { Component, OnInit } from '@angular/core';
import { CirculoscrollService } from '../../services/circuloscroll.service'

@Component({
  selector: 'app-circuloscroll',
  templateUrl: './circuloscroll.component.html',
  styleUrls: ['./circuloscroll.component.css']
})
export class CirculoscrollComponent implements OnInit {

  constructor(private _sc: CirculoscrollService) { }

  ngOnInit(): void { 
    this._sc.init();
  }

  scroll(){
    this._sc.draw();
  }
}
