import { Injectable } from '@angular/core';
import * as $ from '../modules/jquery-3.5.1.min.js';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  constructor() {}

  resize() {
    this.home();
    this.fichas();
    this.random();
  }

  home() {
    let w = $('#home #circulos .work').width();
    $('#home #circulos .work').css({ height: w });
  }

  fichas() {
    let ancho = $(window).width();
    let alto = $(window).height();
    let fondo = $('#fondo-ficha');

    if (ancho >= alto) {
      fondo.css({ width: ancho, height: ancho });
      fondo.css({ marginLeft: 0, marginTop: (alto - fondo.height()) / 2 });
    } else {
      fondo.css({ width: alto, height: alto });
      fondo.css({ marginLeft: (ancho - fondo.width()) / 2, marginTop: 0 });
    }
  }

  random() {
    $('#random .circulo').css({ height: $('#random .circulo').width() });
  }
}
