import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculosService {

  constructor() { }

  calculaPorcentaje(numeroVariable: number, numeroMaximo: number) {
    return Math.floor((numeroVariable / numeroMaximo) * 100);
  }

  invertirPorcentaje(num: number) {
    return Math.abs(num - 100);
  }
}
