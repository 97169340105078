<div id="about">
  <div class="exit" (touchstart)="exit()"></div>
  <div
    class="year"
    id="{{ y20.id }}"
    (mouseleave)="circuloClose(y20.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y20.id, $event)" (touchstart)="circuloOpen(y20.id, $event)">
      {{ y20.id }}
    </p>
    <div class="circulo">
      <div class="content">
        <div class="columna">
          <span>
            <p class="experience">{{ y20.content[0].text }}</p>
            <p class="date">{{ y20.content[0].fecha }}</p>
          </span>
          <span>
            <p class="experience">GRAPHIC DESIGNER & PHOTOGRAPHER X <a href="www.naturaselection.com" target="_blank">NATURA</a></p>
            <p class="date">{{ y20.content[1].fecha }}</p></span
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="year"
    id="{{ y19.id }}"
    (mouseleave)="circuloClose(y19.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y19.id, $event)" (touchstart)="circuloOpen(y19.id, $event)">
      {{ y19.id }}
    </p>
    <div class="circulo">
      <div class="content-large">
        <div class="bloque">
          <span>
            <p class="experience">{{ y19.content[0].text }}</p>
            <p class="date">{{ y19.content[0].fecha }}</p>
          </span>
          <span>
            <p class="experience">ART DIRECTOR X <a href="https://www.playground.media/" target="_blank">PLAYGROUND</a></p>
            <p class="date">{{ y19.content[1].fecha }}</p>
          </span>
        </div>
        <div class="bloque">
          <span>
            <p class="experience">COURSE: THE CONCEPTION AND IDEA DEVELOPEMENT OF A COMMERCIAL <a href="https://www.sae.edu/deu/de/campus/berlin" target="_blank">SAE</a></p>
            <p class="date">{{ y19.content[2].fecha }}</p>
          </span>
          <span>
            <p class="experience">INTERNATIONAL VOLUNTEER X TECH OPEN AIR <a href="https://toa.berlin/" target="_blank">(TOA)</a></p>
            <p class="date">{{ y19.content[3].fecha }}</p>
          </span>
        </div>
      </div>
      <span class="last material-icons" (click)="last(y19.id)"> play_arrow </span>
      <span class="next material-icons" (click)="next(y19.id)"> play_arrow </span>
    </div>
  </div>

  <div
    class="year"
    id="{{ y18.id }}"
    (mouseleave)="circuloClose(y18.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y18.id, $event)" (touchstart)="circuloOpen(y18.id, $event)">
      {{ y18.id }}
    </p>
    <div class="circulo">
      <div class="content-large">
        <div class="bloque">
          <span>
            <p class="experience">{{ y18.content[0].text }}</p>
            <p class="date">{{ y18.content[0].fecha }}</p> </span
          ><span>
            <p class="experience">ART DIRECTOR X <a href="https://www.playground.media/" target="_blank">PLAYGROUND</a></p>
            <p class="date">{{ y18.content[1].fecha }}</p>
          </span>
        </div>
        <div class="bloque">
          <span style="width: 100%">
            <p class="experience">{{ y18.content[2].text }}</p>
            <p class="date">{{ y18.content[2].fecha }}</p>
          </span>
        </div>
      </div>
      <span class="last material-icons" (click)="last(y18.id)"> play_arrow </span>
      <span class="next material-icons" (click)="next(y18.id)"> play_arrow </span>
    </div>
  </div>

  <div
    class="year"
    id="{{ y17.id }}"
    (mouseleave)="circuloClose(y17.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y17.id, $event)" (touchstart)="circuloOpen(y17.id, $event)">
      {{ y17.id }}
    </p>
    <div class="circulo">
      <div class="content">
        <div class="columna">
          <span>
            <p class="experience">ART DIRECTOR X <a href="https://www.playground.media/" target="_blank">PLAYGROUND</a></p>
            <p class="date">{{ y17.content[0].fecha }}</p>
          </span>
          <span>
            <p class="experience">VOLUNTEER X <a href="https://offf.barcelona/es/" target="_blank">OFFF FESTIVAL</a></p>
            <p class="date">{{ y17.content[1].fecha }}</p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="year"
    id="{{ y16.id }}"
    (mouseleave)="circuloClose(y16.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y16.id, $event)" (touchstart)="circuloOpen(y16.id, $event)">
      {{ y16.id }}
    </p>
    <div class="circulo">
      <div class="content">
        <div class="columna">
          <span>
            <p class="experience">POSTGRADUATE IN ART DIRECTION X <a href="https://www.elisava.net/" target="_blank">ELISAVA</a></p>
            <p class="date">{{ y16.content[0].fecha }}</p>
          </span>
          <span>
            <p class="experience">VOLUNTEER X <a href="https://offf.barcelona/es/" target="_blank">OFFF FESTIVAL</a></p>
            <p class="date">{{ y16.content[1].fecha }}</p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="year"
    id="{{ y15.id }}"
    (mouseleave)="circuloClose(y15.id, $event)"
  >
    <p class="año" (mouseenter)="circuloOpen(y15.id, $event)" (touchstart)="circuloOpen(y15.id, $event)">
      {{ y15.id }}
    </p>
    <div class="circulo">
      <div class="content-large">
        <div class="bloque">
          <span>
            <p class="experience">GRAPHIC DESIGNER & PHOTOGRAPHER X <a href="https://doiydesign.com/" target="_blank">DOIY DESIGN</a></p>
            <p class="date">{{ y15.content[0].fecha }}</p>
          </span>
          <span>
            <p class="experience">{{ y15.content[1].text }}</p>
            <p class="date">{{ y15.content[1].fecha }}</p>
          </span>
        </div>
        <div class="bloque">
          <span style="width: 100%">
            <p class="experience">{{ y15.content[2].text }}</p>
            <p class="date">{{ y15.content[2].fecha }}</p>
          </span>
        </div>
      </div>
      <span class="last material-icons" (click)="last(y15.id)"> play_arrow </span>
      <span class="next material-icons" (click)="next(y15.id)"> play_arrow </span>
    </div>
  </div>
</div>

<!-- Mobile version -->

<!-- <div id="about" *ngIf="mobile">
  <div class="exit" (click)="exit()"></div>
  
    <div class="year" id="{{ y20.id }}" (mouseleave)="circuloClose(y20.id)">
      <p class="año" (touchstart)="circuloOpen(y20.id)">
        {{ y20.id }}
      </p>
      <div class="circulo">
        <div class="content">
          <div class="columna">
            <span>
              <p class="experience">{{ y20.content[0].text }}</p>
              <p class="date">{{ y20.content[0].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y20.content[1].text }}</p>
              <p class="date">{{ y20.content[1].fecha }}</p></span>
          </div>
        </div>
      </div>
    </div>

    <div class="year" id="{{ y19.id }}" (mouseleave)="circuloClose(y19.id)">
      <p class="año" (touchstart)="circuloOpen(y19.id)">
        {{ y19.id }}
      </p>
      <div class="circulo">
        <div class="content-mobile">
          <div class="columna-mobile">
            <span>
              <p class="experience">{{ y19.content[0].text }}</p>
              <p class="date">{{ y19.content[0].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y19.content[1].text }}</p>
              <p class="date">{{ y19.content[1].fecha }}</p>
            </span>
          </div>
          <div class="columna-mobile">
            <span>
              <p class="experience">{{ y19.content[2].text }}</p>
              <p class="date">{{ y19.content[2].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y19.content[3].text }}</p>
              <p class="date">{{ y19.content[3].fecha }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="year" id="{{ y18.id }}" (mouseleave)="circuloClose(y18.id)">
      <p class="año" (mouseenter)="circuloOpen(y18.id)">
        {{ y18.id }}
      </p>
      <div class="circulo">
        <div class="content">
          <div class="fila">
            <span>
              <p class="experience">{{ y18.content[0].text }}</p>
              <p class="date">{{ y18.content[0].fecha }}</p> </span
            ><span>
              <p class="experience">{{ y18.content[1].text }}</p>
              <p class="date">{{ y18.content[1].fecha }}</p>
            </span>
          </div>
          <div class="fila">
            <span style="width: 100%">
              <p class="experience">{{ y18.content[2].text }}</p>
              <p class="date">{{ y18.content[2].fecha }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="year" id="{{ y17.id }}" (mouseleave)="circuloClose(y17.id)">
      <p class="año" (mouseenter)="circuloOpen(y17.id)">
        {{ y17.id }}
      </p>
      <div class="circulo">
        <div class="content">
          <div class="columna">
            <span>
              <p class="experience">{{ y17.content[0].text }}</p>
              <p class="date">{{ y17.content[0].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y17.content[1].text }}</p>
              <p class="date">{{ y17.content[1].fecha }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="year" id="{{ y16.id }}" (mouseleave)="circuloClose(y16.id)">
      <p class="año" (mouseenter)="circuloOpen(y16.id)">
        {{ y16.id }}
      </p>
      <div class="circulo">
        <div class="content">
          <div class="columna">
            <span>
              <p class="experience">{{ y16.content[0].text }}</p>
              <p class="date">{{ y16.content[0].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y16.content[1].text }}</p>
              <p class="date">{{ y16.content[1].fecha }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="year" id="{{ y15.id }}" (mouseleave)="circuloClose(y15.id)">
      <p class="año" (mouseenter)="circuloOpen(y15.id)">
        {{ y15.id }}
      </p>
      <div class="circulo">
        <div class="content">
          <div class="fila">
            <span>
              <p class="experience">{{ y15.content[0].text }}</p>
              <p class="date">{{ y15.content[0].fecha }}</p>
            </span>
            <span>
              <p class="experience">{{ y15.content[1].text }}</p>
              <p class="date">{{ y15.content[1].fecha }}</p>
            </span>
          </div>
          <div class="fila">
            <span style="width: 100%">
              <p class="experience">{{ y15.content[2].text }}</p>
              <p class="date">{{ y15.content[2].fecha }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
</div> -->
