<!-- <app-circuloscroll></app-circuloscroll> -->
<div id="fondo-ficha" (window:resize)="resize()" (window:scroll)="fondo()"></div>

<div id="fichas">

  <div class="menu">
    <h2 *ngFor="let x of content; let i = index" (click)="click(i)">
      {{ x.titulo | uppercase }}
    </h2>
    <h1>{{ titulo | uppercase }}</h1>
  </div>

  <div class="ficha" *ngFor="let x of content">

    <div class="titulo">
      <h2>{{ x.tituloCompleto | uppercase }}</h2>
      <p class="fecha">{{ x.fecha | uppercase }}</p>
    </div>

    <div class="imagenes">
      <figure *ngFor="let img of x.imgs">
        <img [src]="img" />
      </figure>
    </div>

    <div class="parrafos">
      <p class="descripcion">
        {{ x.descripcion | uppercase }}
      </p>
      <p class="equipo">
        {{ x.equipo | uppercase }}
      </p>
    </div>
    
  </div>
</div>
