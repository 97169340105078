import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  
  rosa = 'var(--rosa)';
  rosapalo = 'var(--rosapalo)';
  naranja = 'var(--naranja)';
  azul = 'var(--azul)';
  verde = 'var(--verde)';
  lima = 'var(--lima)';
  blancoroto = 'var(--blancoroto)';

  constructor() {
  }

  getRosa(){
    return this.rosa;
  }
  getRosapalo(){
    return this.rosapalo;
  }
  getNaranja(){
    return this.naranja;
  }
  getAzul(){
    return this.azul;
  }
  getVerde(){
    return this.verde;
  }
  getLima(){
    return this.lima;
  }
  getBlancoroto(){
    return this.blancoroto;
  }

}
