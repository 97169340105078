import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../services/resize.service';
import { FxService } from '../../services/fx.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {


  constructor(private _resizeService: ResizeService, private _fx: FxService) { }

  ngOnInit(): void {
    this._fx.init('inicio');
    this._resizeService.resize();
    this.storage();
  }
  
  scrolling(){
    this._fx.scroll('inicio');
  }
  
  resize(){
    this._resizeService.resize();
  }

  storage(){
    let s = window.localStorage;
    let resultado = s.getItem('work');

    if(resultado != null){
      this._fx.scrollToWork();
      s.removeItem('work');
    }
  }
}
